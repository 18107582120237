import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";

function Footer(props) {
  let footerData = props.data;
  const [countryCode, setCountryCode] = useState('us');

  const fadeAnimation = () => {
    var elementReveals = document.querySelectorAll(".footerTitle, .footerParagraph");

    for (var i = 0; i < elementReveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = elementReveals[i].getBoundingClientRect().top;
      var elementVisible = 10;

      if (elementTop < windowHeight - elementVisible) {
        elementReveals[i].classList.add("active");
      } else {
        elementReveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", fadeAnimation);


  return (
    <>
      {
        footerData ? (

          <footer className="footer" role="navigation" aria-label="footer navigation">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 w-100 footerRow position-relative">
                  <div id="footer-background">
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-1 footerSides">
                </div>
                <div className="col-lg-10 footerRow position-relative">
                  <div id="myFooter">
                    <>
                      {
                        footerData[1].data.footer ? (
                          <div id="footerCopy">
                            {parse(footerData[1].data.footer)}
                          </div>
                        ) : null
                      }
                    </>
                    <>
                    {
                      footerData[0].menu ? (
                        <p className="footerParagraph active footer-nav-links" id="copyright">
                          {
                            footerData[0].menu.map((data, i) =>
                              <span key={i} className={`cookie-footer ${countryCode} ${data.title === "Your Privacy Choices" ? 'privacy-choice' : ''}  ${data.title === "Cookie Notice" ? 'cookie-notice' : ''}`}>
                                {
                                  data.title === "Your Privacy Choices" ? (
                                    <a target="_blank" rel="noreferrer" href={data.absolute} className={`footer-nav-item choice-icon ${countryCode}`}>Your Privacy Choices</a>
                                  ) : (
                                    <a target="_blank" rel="noreferrer" href={data.absolute} title={data.title} className={`footer-nav-item ${countryCode}`}>{data.title}</a>
                                  )
                                }
                              </span>
                            )
                          }
                        </p>
                      ) : (
                        null
                      )
                    }
                    </>
                    <p className="footerParagraph active copyright-txt copyright">&copy; {(new Date().getFullYear())} NBCUniversal Creative Group </p>
                  </div>

                </div>
                <div className="col-lg-1 footerSides">
                </div>
              </div>
            </div>
          </footer>

        ) : (
          null
        )
      }
    </>
  )
}

export default Footer;